@tailwind base;
@tailwind components;
@tailwind utilities;
iframe#webpack-dev-server-client-overlay {
  display: none !important;
}
.auth-slider .slick-arrow {
  z-index: 99;
}

.auth-slider .slick-next {
  right: 50px;
  display: flex !important;
}

.auth-slider .slick-prev {
  right: 123px;
  left: auto;
  display: flex !important;
}

.auth-slider .slick-prev::before,
.auth-slider .slick-next::before {
  content: none;
}

.auth-swiper.slick-slider,
.auth-swiper .slick-list,
.auth-swiper .slick-track,
.auth-swiper .slick-slide > div {
  height: 100%;
}

@media (max-width: 600px) {
  .auth-slider .slick-next {
    right: 0px;
    top: auto;
    bottom: 0;
  }

  .auth-slider .slick-prev {
    right: 80px;
    top: auto;
    bottom: 0;
  }
}

@media (max-width: 500px) {
  .auth-slider .slick-prev {
    right: 50px;
  }
}

/* Navbar */
.navbar-icon svg path {
  stroke: #cccccc;
  opacity: 0.4;
}

.navbar-icon-active svg path {
  stroke: white;
}

/* Home */
/* Hide scrollbar for Chrome, Safari and Opera */
.keep-scrolling::-webkit-scrollbar {
  display: none;
}

/* Hide scrollbar for IE, Edge and Firefox */
.keep-scrolling {
  -ms-overflow-style: none; /* IE and Edge */
  scrollbar-width: none; /* Firefox */
}

.rhap_container {
  background: transparent;
  box-shadow: none;
  padding: 0;
}

.rhap_time {
  font-weight: 400;
  font-size: 0.8125rem;
  line-height: 16px;
  color: white;
  opacity: 0.7;
}

.rhap_progress-indicator {
  display: none;
}

.rhap_progress-bar,
.rhap_volume-bar {
  border-radius: 20px;
}

.rhap_progress-filled {
  background-color: white;
}

.rhap_skip-button {
  cursor: not-allowed;
}

.rhap_skip-button svg path {
  fill: rgb(229, 229, 229);
}

@media (max-width: 500px) {
  .rhap_volume-controls {
    display: none;
  }
  /* .rhap_button-clear {
    width: 20px;
    height: 20px;
    font-size: 20px;
  } */
}

.rhap_button-clear:not(.rhap_skip-button) svg path {
  fill: white;
}

.rhap_download-progress {
  background-color: white;
  opacity: 0;
}

.rhap_volume-indicator {
  background-color: white;
  opacity: 1;
}

.rhap_volume-bar {
  background-color: white;
}

/*  */

.bar {
  position: absolute;
  bottom: 0px;
  height: 3px;
  width: 3px;
  background: #0071ff;
  animation: sound 0ms -800ms linear infinite alternate;
  /* transform: translateY(50%); */
}

.inactive-bar {
  position: absolute;
  bottom: 0px;
  height: 3px;
  width: 3px;
  background: darkgray;
  transform: translateY(50%);
}

@keyframes sound {
  0% {
    opacity: 0.35;
    height: 3px;
  }
  100% {
    opacity: 1;
    height: 12px;
  }
}

/*  */
.material-bubble {
  overflow: hidden;
  position: relative;
  transition: color 0.5s, transform 0.2s, background-color 0.2s;
  z-index: 2;
  /* transition: none; */
}

.material-bubble.light {
  outline: 1px solid #0071ff;
}

.material-bubble:hover {
  color: white;
  /* background-color: #0071ff; */
  /* transition: background-color 0s 0.3s; */
}

.material-bubble::before {
  content: "";
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  transition: opacity 0.3s, outline 0.3s;
}

.material-bubble:hover::before {
  opacity: 0;
}

.material-bubble::after {
  content: "";
  position: absolute;
  width: 200px;
  height: 200px;
  background-color: #0071ff;
  outline-color: transparent;
  border-radius: 50%;
  transform: scale(0.1);
  opacity: 0;
  z-index: -1;
  transition: transform 0.3s, opacity 0.3s, background-color 0.3s;
}

.material-bubble:hover::after {
  opacity: 1;
  transform: scale(1);
  width: 100%;
  border-radius: 4px;
}

.material-bubble.dark::after {
  background-color: rgba(0, 0, 0, 0.85);
}
.material-bubble.light:hover {
  outline: 1px solid #0071ff;
  color: #0071ff;
}

.material-bubble.light::after {
  background-color: white;
}

/*  */
.luxury {
  background-image: linear-gradient(
    45deg,
    transparent 0%,
    transparent 75%,
    rgba(255, 255, 255, 0.8) 75%,
    transparent 100%
  );
  flex: 1 auto;
  transition: all 1.2s ease-in-out;
}
.luxury:hover {
  background-position: 150% top;
}
